#__next {
  color: #333333;
}

body {
  overflow-x: auto;
  min-width: 1440px;

  font-family: 'PingFang SC', 'Microsoft YaHei', 'HanHei SC', 'Helvetica Neue', 'Open Sans', Arial, 'Hiragino Sans GB',
    '微软雅黑', STHeiti, 'WenQuanYi Micro Hei', SimSun, sans-serif;
}

.form-item-label::after {
  content: ':';
  position: relative;
  top: -1px;
  margin: 0 8px 0 2px;
}

.page-total {
  margin: 0 4px;
  color: #1f1f1f;
}

.count-up {
  font-weight: bold;
  color: #333333;
  margin: 0 8px;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 150ms;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ea4335;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ea4335, 0 0 5px #ea4335;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

.link,
.link__underline {
  color: #4a90e2 !important;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.link__underline {
  text-decoration: underline;
}

.link__underline + .link__underline {
  margin-left: 16px;
}

.link__primary:hover {
  color: #ea4335 !important;
}

.link__underline__warn {
  text-decoration: underline;
  color: #ea4335;
  cursor: pointer;
}

.link__disabled {
  color: #ddd;
  cursor: default;
}

.cart-animation-box {
  position: fixed;
  width: 36px;
  height: 36px;
  z-index: 1050;
}

.cart-animation-box > div {
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  background: #ea4335;
  border-radius: 50%;
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.two-line-break {
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-box-orient: vertical; /* stylelint-disable-line */
  -webkit-line-clamp: 2; /* stylelint-disable-line */
  overflow: hidden;
  text-overflow: ellipsis;
}

td:has(.action-column) {
  background-color: #F9F9F9;
}

@font-face {
  font-family: 'Futura';
  src: url('//static.hellorf.com/hellorf/_next/fonts/Futura-Bold.eot'),
    url('//static.hellorf.com/hellorf/_next/fonts/Futura-Bold.woff2') format('woff2'),
    url('//static.hellorf.com/hellorf/_next/fonts/Futura-Bold.woff') format('woff'),
    url('//static.hellorf.com/hellorf/_next/fonts/Futura-Bold.ttf') format('truetype');
}

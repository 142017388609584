.whee-sdk-theme {
  /* Color styles */
  --background-system-frame-floatpanel: rgba(255, 255, 255, 1);
  --background-system-main-background: linear-gradient(360deg,#ffffff00,#ffffffcc);
  --background-btn-svip: rgba(247, 168, 119, 1);
  --background-btn-hover: rgba(0, 0, 0, 0.08);
  --background-btn-secondary: rgba(255, 255, 255, 1);
  --background-btn-primary: rgba(247, 70, 64, 1);
  --background-input: rgba(249, 249, 249, 1);
  --background-input-transparency: rgba(0, 0, 0, 0.04);
  --background-function-copyright-warning: rgba(255, 241, 214, 1);
  --background-tab-selected: rgba(255, 255, 255, 1);
  --background-tab: rgba(246, 247, 250, 1);
  --background-slide-thumb: rgba(255, 255, 255, 1);
  --background-slide-fill: rgba(255, 94, 77, 1);
  --background-side-fill-hover: rgba(255, 128, 108, 1);
  --background-slide: rgba(237, 237, 237, 1);
  --background-system-space-holder: rgba(246, 246, 246, 1);
  --background-system-scrollbar-active: rgba(0, 0, 0, 0.35);
  --background-system-scrollbar: rgba(0, 0, 0, 0.15);
  --background-checkbox-selected: rgba(255, 94, 77, 1);
  --background-checkbox: rgba(255, 255, 255, 1);
  --background-system-selected: rgba(246, 246, 246, 1);
  --background-tag-selected: rgba(229, 242, 255, 1);
  --background-tag: rgba(246, 247, 250, 1);
  --background-tag-hover: rgba(235, 237, 242, 1);
  --background-hover-tips: rgba(0, 0, 0, 0.5);
  --background-list-hover: rgba(242, 242, 242, 1);
  --background-function-copyright: rgba(228, 252, 222, 1);
  --background-system-drop-list: rgba(255, 255, 255, 1);
  --background-function-customer-service-dialog-box2: rgba(209, 231, 255, 1);
  --background-function-customer-service-dialog-box1: rgba(255, 255, 255, 1);
  --background-function-panel-collapse-hover: rgba(0, 0, 0, 0.15);
  --background-function-panel-collapse: rgba(0, 0, 0, 0.08);
  --background-system-lock: rgba(255, 231, 229, 1);
  --background-system-overlay-mask: rgba(0, 0, 0, 0.5);
  --background-system-notification-badge: rgba(247, 70, 64, 1);
  --background-system-main-background-default: rgba(255, 255, 255, 1);
  --background-input-not-editable: rgba(246, 247, 250, 1);
  --background-tab2: rgba(0, 0, 0, 0.04);
  --background-system-main-background-flat: rgba(246, 247, 250, 1);
  --background-btn-primary2: rgba(156, 107, 255, 1);
  --background-switch-selected: rgba(225, 57, 44, 1);
  --background-switch: rgba(235, 237, 242, 1);
  --background-btn-ai: rgba(247, 70, 64, 1);
  --background-btn-ai-hover: rgba(255, 94, 77, 1);
  --background-web-page: rgba(255, 255, 255, 1);
  --background-web-menu: rgba(255, 255, 255, 1);
  --background-web-menu-selected: linear-gradient(270deg,#f201f7ff,#ffba53ff);
  --background-web-menu-hover: rgba(246, 247, 250, 1);
  --background-web-nav-bar: rgba(255, 255, 255, 1);
  --background-gradient-mask-right: linear-gradient(90deg,#ffffff00,#ffffffff);
  --background-gradient-mask-left: linear-gradient(90deg,#ffffffff,#ffffff00);
  --background-gradient-mask-bottom: linear-gradient(360deg,#ffffff00,#ffffffff);
  --background-pagination-hover: rgba(246, 247, 250, 1);
  --background-web-tag-major: rgba(93, 109, 255, 1);
  --background-web-tag-moderate: rgba(215, 219, 255, 1);
  --background-web-tag-minor: rgba(237, 239, 255, 1);
  --background-web-tag-add: rgba(246, 247, 250, 1);
  --background-card-hover: rgba(246, 247, 250, 1);
  --background-loading-bar: linear-gradient(270deg,#f201f7ff,#ffba53ff);
  --background-tag-amount: rgba(0, 0, 0, 0.5);
  --background-success: rgba(79, 198, 43, 1);
  --background-tag-model-name: rgba(0, 0, 0, 0.04);
  --background-hovertips2: rgba(0, 0, 0, 0.75);
  --background-amplification: rgba(247, 70, 64, 1);
  --background-selected-frame: rgba(28, 29, 31, 1);
  --background-canva: rgba(246, 247, 250, 1);
  --background-amplification-result: rgba(235, 237, 242, 1);
  --background-drop-area: rgba(237, 239, 255, 1);
  --background-amplification-resultt: rgba(235, 237, 242, 1);
  --background-audit: rgba(0, 0, 0, 0.5);
  --background-web-page-secondary: rgba(246, 246, 246, 1);
  --background-tag-new: linear-gradient(270deg,#ea3d3dff,#f97968ff);
  --background-tag-flash-sale: linear-gradient(90deg,#f8b814ff,#fc7717ff);
  --background-tag-hot: linear-gradient(270deg,#ea3d3dff,#f97968ff);
  --background-collection: rgba(255, 182, 73, 1);
  --background-collection-btn: rgba(255, 241, 214, 1);
  --background-multiple-choice: rgba(255, 94, 77, 1);
  --background-released: rgba(0, 0, 0, 0.5);
  --background-whee-vip-btn: linear-gradient(270deg,#ffd281ff,#ffebbcff);
  --background-whee-vip-sale-tag: linear-gradient(90deg,#ff5454ff,#ff7676ff);
  --background-whee-vip-btn-disable: linear-gradient(270deg,#ffd2817f,#ffebbc7f);
  --background-rate: linear-gradient(225deg,#ff9a27ff,#ffb649ff,#ffd480ff);
  --background-not-rated: rgba(235, 237, 242, 1);
  --background-meidou: linear-gradient(360deg,#ffeeb1ff,#f9ac66ff);
  --background-button-on-search-bar: linear-gradient(360deg,#ffffffff,#ffffffcc);
  --background-btn-ai-disable: rgba(184, 191, 255, 1);
  --background-brush: rgba(0, 224, 255, 1);
  --background-tag-work-type: rgba(239, 245, 255, 1);
  --background-btn-meidou: rgba(250, 181, 135, 1);
  --background-category-selected: rgba(53, 73, 255, 1);
  --background-category: rgba(255, 255, 255, 1);
  --background-category-bar: rgba(255, 255, 255, 1);
  --background-nav-bar-selected: rgba(246, 247, 250, 1);
  --background-nav-bar-tag: linear-gradient(180deg,#d1f9ffff,#d8d2ffff,#f1ff9bff);
  --background-nav-bar-tag2: linear-gradient(180deg,#d1f9ffff,#d8d2ffff,#f1ff9bff);
  --background-side-bar: rgba(0, 0, 0, 1);
  --background-side-bar-selected: rgba(46, 48, 51, 1);
  --background-side-bar-hover: rgba(37, 37, 37, 1);
  --background-exegesis: rgba(246, 246, 246, 1);
  --background-system-selected: rgba(255, 255, 255, 0.5);
  --background-toast: rgba(255, 231, 229, 1);
  --background-new-color: rgba(255, 94, 77, 1);
  --background-input-small: rgba(249, 249, 249, 1);
  --background-disable: rgba(220, 220, 220, 1);
  --background-input-hover: rgba(242, 242, 242, 1);
  --background-editor-primary-accent: rgba(51, 136, 255, 1);
  --background-editor-warning: rgba(255, 134, 128, 1);
  --background-editor-select-accent: rgba(93, 109, 255, 1);
  --content-function-tool-nav: rgba(28, 29, 31, 1);
  --content-btn-transparency: rgba(28, 29, 31, 1);
  --content-btn-transparency-disable: rgba(208, 210, 214, 1);
  --content-btn-secondary: rgba(28, 29, 31, 1);
  --content-btn-primary: rgba(255, 255, 255, 1);
  --content-btn-secondary-pressed: rgba(171, 173, 178, 1);
  --content-btn-secondary-hover: rgba(53, 73, 255, 1);
  --content-input-place-holder: rgba(203, 203, 203, 1);
  --content-input-lable: rgba(170, 170, 170, 1);
  --content-input: rgba(13, 13, 13, 1);
  --content-input-lable-transparency: rgba(0, 0, 0, 0.35);
  --content-function-copyright-warning: rgba(128, 74, 13, 1);
  --content-tab-selected: rgba(28, 29, 31, 1);
  --content-tab: rgba(97, 99, 102, 1);
  --content-system-primary: rgba(28, 29, 31, 1);
  --content-system-secondary: rgba(97, 99, 102, 1);
  --content-system-tertiary: rgba(147, 149, 153, 1);
  --content-system-quaternary: rgba(171, 173, 178, 1);
  --content-system-quinary: rgba(208, 210, 214, 1);
  --content-system-link: rgba(225, 57, 44, 1);
  --content-checkbox-selected: rgba(255, 255, 255, 1);
  --content-system-selected: rgba(51, 136, 255, 1);
  --content-tag-selected: rgba(51, 136, 255, 1);
  --content-tag: rgba(77, 79, 82, 1);
  --content-hover-tips: rgba(255, 255, 255, 1);
  --content-list-selected: rgba(237, 237, 237, 1);
  --content-list-secondary: rgba(171, 173, 178, 1);
  --content-list-primary: rgba(28, 29, 31, 1);
  --content-function-copyright: rgba(29, 89, 11, 1);
  --content-function-panel-collapse-hover: rgba(0, 0, 0, 0.5);
  --content-function-panel-collapse: rgba(0, 0, 0, 0.35);
  --content-system-info-icon-warning: rgba(255, 134, 128, 1);
  --content-system-info-icon-regular: rgba(184, 191, 255, 1);
  --content-system-lock: rgba(247, 70, 64, 1);
  --content-system-info-icon-success: rgba(150, 229, 125, 1);
  --content-input-not-editable: rgba(147, 149, 153, 1);
  --content-btn-primary-tips: rgba(255, 255, 255, 0.5);
  --content-function-selection-add: rgba(51, 136, 255, 1);
  --content-function-selection-remove: rgba(247, 70, 64, 1);
  --content-switch: rgba(255, 255, 255, 1);
  --content-switch-selected: rgba(255, 255, 255, 1);
  --content-gradient-mask-right: linear-gradient(90deg,#ffffff00,#ffffffff);
  --content-gradient-mask-left: linear-gradient(90deg,#ffffffff,#ffffff00);
  --content-gradient-mask-bottom: linear-gradient(360deg,#ffffff00,#ffffffff);
  --content-web-primary: rgba(247, 70, 64, 1);
  --content-web-menu-primary: rgba(28, 29, 31, 1);
  --content-web-menu-secondary: rgba(97, 99, 102, 1);
  --content-web-menu-tertiary: rgba(147, 149, 153, 1);
  --content-web-menu-selected: rgba(255, 255, 255, 1);
  --content-pagination-primary: rgba(28, 29, 31, 1);
  --content-pagination-selected: rgba(53, 73, 255, 1);
  --content-pagination-secondary: rgba(147, 149, 153, 1);
  --content-web-tag-major: rgba(255, 255, 255, 1);
  --content-web-tag-moderate: rgba(53, 73, 255, 1);
  --content-web-tag-minor: rgba(53, 73, 255, 1);
  --content-web-tag-add: rgba(28, 29, 31, 1);
  --content-tag-special-offer: linear-gradient(90deg,#ffa1c8ff,#ba99fbff);
  --content-system-link-hover: rgba(93, 109, 255, 1);
  --content-collection: rgba(255, 182, 73, 1);
  --content-btn-disable: rgba(220, 220, 220, 1);
  --content-on-vip-btn: rgba(60, 36, 4, 1);
  --content-whee-vip-logo: linear-gradient(90deg,#3c2404ff,#5e3c00ff);
  --content-on-whee-vip-mark: rgba(255, 255, 255, 1);
  --content-on-whee-vip-sale-tag: rgba(255, 255, 255, 1);
  --content-on-whee-vip-logo: rgba(255, 255, 255, 1);
  --content-web-tag-selected: rgba(53, 73, 255, 1);
  --content-web-footer-secondary: rgba(97, 99, 102, 1);
  --content-web-footer-primary: rgba(28, 29, 31, 1);
  --content-tag-work-type: rgba(51, 136, 255, 1);
  --content-btn-meidou: rgba(87, 51, 27, 1);
  --content-icon-btn-hover: rgba(53, 73, 255, 1);
  --content-tag-liner: rgba(153, 153, 153, 1);
  --content-category-selected: rgba(255, 255, 255, 1);
  --content-category: rgba(28, 29, 31, 1);
  --content-nav-bar-selected: rgba(28, 29, 31, 1);
  --content-nav-bar-default: rgba(171, 173, 178, 1);
  --content-nav-bar-gradient: linear-gradient(90deg,#8e66ffff,#fb2dffff,#ffad33ff);
  --content-nav-bar-tag: rgba(28, 29, 31, 1);
  --content-side-bar: rgba(0, 224, 255, 1);
  --content-side-bar-selected: rgba(255, 255, 255, 1);
  --content-side-bar: rgba(107, 107, 107, 1);
  --content-system-secondary-light: rgba(203, 203, 203, 1);
  --content-system-primary-light: rgba(249, 249, 249, 1);
  --stroke-btn-secondary: rgba(242, 242, 242, 1);
  --stroke-input-selected: rgba(225, 57, 44, 1);
  --stroke-input: rgba(208, 210, 214, 1);
  --stroke-system-border-overlay: rgba(0, 0, 0, 0.08);
  --stroke-system-separator: rgba(0, 0, 0, 0.08);
  --stroke-input-error: rgba(255, 161, 139, 1);
  --stroke-input-error-focus: rgba(247, 70, 64, 0.3);
  --stroke-input-focus: rgba(247, 70, 64, 0.3);
  --stroke-slide-thumb-focus: rgba(247, 70, 64, 0.3);
  --stroke-slide-thumb-selected: rgba(225, 57, 44, 1);
  --stroke-slide-thumb: rgba(247, 70, 64, 1);
  --stroke-system-selected: rgba(247, 70, 64, 1);
  --stroke-system-wide-separator: rgba(246, 247, 250, 1);
  --stroke-checkbox: rgba(208, 210, 214, 1);
  --stroke-drop-area-hover: rgba(247, 70, 64, 1);
  --stroke-drop-area: rgba(235, 237, 242, 1);
  --stroke-drop-area-transparent-hover: rgba(102, 168, 255, 1);
  --stroke-drop-area-transparent: rgba(0, 0, 0, 0.15);
  --stroke-system-panel-separator: rgba(235, 237, 242, 1);
  --stroke-dropdown-border: rgba(255, 255, 255, 1);
  --stroke-btn-transparency: rgba(0, 0, 0, 0.15);
  --stroke-pagination-selected: rgba(53, 73, 255, 1);
  --stroke-pagination-group: rgba(235, 237, 242, 1);
  --stroke-tag-special-offer: linear-gradient(90deg,#ffa1c8ff,#ba99fbff);
  --stroke-collection: rgba(255, 182, 73, 1);
  --stroke-amplification: rgba(247, 70, 64, 1);
  --stroke-alignment-line: rgba(28, 29, 31, 1);
  --stroke-selected-frame: rgba(28, 29, 31, 1);
  --stroke-avatar: rgba(0, 0, 0, 0.04);
  --stroke-web-primary: rgba(53, 73, 255, 1);
  --stroke-web-tag-selected: rgba(53, 73, 255, 1);
  --stroke-search-bar: linear-gradient(270deg,#f201f7ff,#ffba53ff);
  --stroke-brush: rgba(255, 255, 255, 1);
  --stroke-icon-btn-hover: rgba(0, 0, 0, 0.08);
  --stroke-tag-liner: rgba(137, 137, 137, 1);
  --stroke-category: rgba(0, 0, 0, 0.04);
  --stroke-toast: rgba(247, 70, 64, 1);
  --stroke-new-stroke: rgba(237, 237, 237, 1);
  --stroke-stroke-input: rgba(220, 220, 220, 1);
  --base-natural-dark-100: rgba(240, 241, 245, 1);
  --base-natural-dark-90: rgba(213, 214, 217, 1);
  --base-natural-dark-80: rgba(186, 187, 191, 1);
  --base-natural-dark-70: rgba(159, 161, 166, 1);
  --base-natural-dark-60: rgba(134, 136, 140, 1);
  --base-natural-dark-50: rgba(97, 99, 102, 1);
  --base-natural-dark-40: rgba(78, 79, 82, 1);
  --base-natural-dark-30: rgba(63, 64, 66, 1);
  --base-natural-dark-25: rgba(46, 48, 51, 1);
  --base-natural-dark-20: rgba(38, 39, 41, 1);
  --base-natural-dark-15: rgba(28, 29, 31, 1);
  --base-natural-dark-10: rgba(18, 19, 20, 1);
  --base-natural-light-100: rgba(28, 29, 31, 1);
  --base-natural-light-90: rgba(52, 54, 56, 1);
  --base-natural-light-80: rgba(77, 79, 82, 1);
  --base-natural-light-70: rgba(97, 99, 102, 1);
  --base-natural-light-60: rgba(121, 123, 127, 1);
  --base-natural-light-50: rgba(147, 149, 153, 1);
  --base-natural-light-40: rgba(171, 173, 178, 1);
  --base-natural-light-30: rgba(208, 210, 214, 1);
  --base-natural-light-20: rgba(235, 237, 242, 1);
  --base-natural-light-10: rgba(246, 247, 250, 1);
  --base-natural-light-0: rgba(255, 255, 255, 1);
  --base-white-opacity-100: rgba(255, 255, 255, 1);
  --base-white-opacity-75: rgba(255, 255, 255, 0.75);
  --base-white-opacity-65: rgba(255, 255, 255, 0.65);
  --base-white-opacity-50: rgba(255, 255, 255, 0.5);
  --base-white-opacity-25: rgba(255, 255, 255, 0.25);
  --base-white-opacity-15: rgba(255, 255, 255, 0.15);
  --base-white-opacity-8: rgba(255, 255, 255, 0.08);
  --base-black-opacity-100: rgba(0, 0, 0, 1);
  --base-black-opacity-75: rgba(0, 0, 0, 0.75);
  --base-black-opacity-65: rgba(0, 0, 0, 0.65);
  --base-black-opacity-50: rgba(0, 0, 0, 0.5);
  --base-black-opacity-35: rgba(0, 0, 0, 0.35);
  --base-black-opacity-30: rgba(0, 0, 0, 0.3);
  --base-black-opacity-20: rgba(0, 0, 0, 0.2);
  --base-black-opacity-15: rgba(0, 0, 0, 0.15);
  --base-black-opacity-8: rgba(0, 0, 0, 0.08);
  --base-black-opacity-4: rgba(0, 0, 0, 0.04);
  --base-brand-light-100: rgba(26, 36, 128, 1);
  --base-brand-light-90: rgba(32, 44, 153, 1);
  --base-brand-light-80: rgba(37, 51, 178, 1);
  --base-brand-light-70: rgba(43, 59, 204, 1);
  --base-brand-light-60: rgba(48, 66, 229, 1);
  --base-brand-light-50: rgba(53, 73, 255, 1);
  --base-brand-light-40: rgba(93, 109, 255, 1);
  --base-brand-light-30: rgba(140, 152, 255, 1);
  --base-brand-light-20: rgba(184, 191, 255, 1);
  --base-brand-light-10: rgba(215, 219, 255, 1);
  --base-brand-light-5: rgba(237, 239, 255, 1);
  --base-gold-light-100: rgba(101, 54, 25, 1);
  --base-gold-light-90: rgba(128, 73, 39, 1);
  --base-gold-light-80: rgba(205, 116, 62, 1);
  --base-gold-light-70: rgba(231, 139, 83, 1);
  --base-gold-light-60: rgba(240, 155, 103, 1);
  --base-gold-light-50: rgba(247, 168, 119, 1);
  --base-gold-light-40: rgba(250, 185, 145, 1);
  --base-gold-light-30: rgba(255, 202, 168, 1);
  --base-gold-light-20: rgba(255, 221, 199, 1);
  --base-gold-light-10: rgba(255, 237, 224, 1);
  --base-green-light-90: rgba(29, 89, 11, 1);
  --base-green-light-70: rgba(46, 140, 17, 1);
  --base-green-light-50: rgba(79, 198, 43, 1);
  --base-green-light-30: rgba(150, 229, 125, 1);
  --base-green-light-10: rgba(228, 252, 222, 1);
  --base-yellow-light-90: rgba(128, 74, 13, 1);
  --base-yellow-light-70: rgba(198, 125, 30, 1);
  --base-yellow-light-50: rgba(255, 182, 73, 1);
  --base-yellow-light-30: rgba(255, 213, 128, 1);
  --base-yellow-light-10: rgba(255, 241, 214, 1);
  --base-red-light-90: rgba(103, 11, 11, 1);
  --base-red-light-70: rgba(167, 27, 24, 1);
  --base-red-light-50: rgba(247, 70, 64, 1);
  --base-red-light-30: rgba(255, 134, 128, 1);
  --base-red-light-10: rgba(255, 231, 229, 1);
  --base-red-light-opacity-30: rgba(247, 70, 64, 0.3);
  --base-primary-light-opacity-30: rgba(51, 136, 255, 0.3);
  --base-gradient-ai-light: rgba(53, 73, 255, 1);
  --base-gradient-ai-dark: linear-gradient(90deg,#ffb033ff,#f201f7ff);
  --base-primary-light-100: rgba(5, 54, 128, 1);
  --base-primary-light-90: rgba(3, 73, 172, 1);
  --base-primary-light-80: rgba(10, 91, 214, 1);
  --base-primary-light-70: rgba(27, 110, 234, 1);
  --base-primary-light-60: rgba(30, 121, 250, 1);
  --base-primary-light-50: rgba(51, 136, 255, 1);
  --base-primary-light-40: rgba(102, 168, 255, 1);
  --base-primary-light-30: rgba(140, 192, 255, 1);
  --base-primary-light-20: rgba(209, 231, 255, 1);
  --base-primary-light-10: rgba(229, 242, 255, 1);
  --base-primary-light-5: rgba(243, 249, 255, 1);
  --base-primary2-light-100: rgba(57, 31, 102, 1);
  --base-primary2-light-90: rgba(82, 44, 148, 1);
  --base-primary2-light-80: rgba(105, 59, 184, 1);
  --base-primary2-light-70: rgba(124, 70, 219, 1);
  --base-primary2-light-60: rgba(143, 88, 252, 1);
  --base-primary2-light-50: rgba(156, 107, 255, 1);
  --base-primary2-light-40: rgba(190, 153, 255, 1);
  --base-primary2-light-30: rgba(206, 178, 255, 1);
  --base-primary2-light-20: rgba(235, 224, 255, 1);
  --base-primary2-light-10: rgba(246, 242, 255, 1);
  --base-gradient-mask-bottom-light: linear-gradient(360deg,#ffffff00,#ffffffff);
  --base-gradient-mask-right-light: linear-gradient(90deg,#ffffff00,#ffffffff);
  --base-gradient-mask-left-light: linear-gradient(90deg,#ffffffff,#ffffff00);
  --base-gradient-mask-left-dark: linear-gradient(90deg,#262729ff,#26272900);
  --base-gradient-mainbackground-dark: linear-gradient(360deg,#0e0e0fff,#1c1d1fff);
  --base-gradient-mask-bottom-dark: linear-gradient(360deg,#26272900,#262729ff);
  --base-gradient-mask-right-dark: linear-gradient(90deg,#26272900,#262729ff);
  --base-gradient-mainbackground-light: linear-gradient(360deg,#ffffff00,#ffffffcc);
  --base-cornflower-light-100: rgba(26, 36, 128, 1);
  --base-cornflower-light-90: rgba(32, 44, 153, 1);
  --base-cornflower-light-80: rgba(37, 51, 178, 1);
  --base-cornflower-light-70: rgba(43, 59, 204, 1);
  --base-cornflower-light-60: rgba(48, 66, 229, 1);
  --base-cornflower-light-50: rgba(53, 73, 255, 1);
  --base-cornflower-light-40: rgba(93, 109, 255, 1);
  --base-cornflower-light-30: rgba(140, 152, 255, 1);
  --base-cornflower-light-20: rgba(184, 191, 255, 1);
  --base-cornflower-light-10: rgba(215, 219, 255, 1);
  --base-cornflower-light-5: rgba(237, 239, 255, 1);
  --base-turquoise-blue-light-50: rgba(23, 170, 252, 1);
  --base-orange-light-50: rgba(255, 154, 39, 1);
  --base-gradient-tag-special-offer: linear-gradient(90deg,#ffa1c8ff,#ba99fbff);
  --base-gradient-magenta: linear-gradient(270deg,#f201f7ff,#ffba53ff);
  --base-gradient-blue: linear-gradient(270deg,#0693f7ff,#3eccceff);
  --base-gradient-orange: linear-gradient(90deg,#f8b814ff,#fc7717ff);
  --base-gradient-orange-2: linear-gradient(225deg,#ff9a27ff,#ffb649ff,#ffd480ff);
  --base-gradient-red: linear-gradient(270deg,#ea3d3dff,#f97968ff);
  --base-gradient-pink: linear-gradient(270deg,#ea3d3dff,#f97968ff);
  --base-gradient-brown: linear-gradient(90deg,#3c2404ff,#5e3c00ff);
  --base-brown: rgba(60, 36, 4, 1);
  --base-gradient-gold: linear-gradient(270deg,#ffd281ff,#ffebbcff);
  --base-gradient-gold-2: linear-gradient(270deg,#ffd2817f,#ffebbc7f);
  --base-gradient-gold-3: linear-gradient(360deg,#ffeeb1ff,#f9ac66ff);
  --base-gradient-red-2: linear-gradient(90deg,#ff5454ff,#ff7676ff);
  --base-gradient-grey: linear-gradient(360deg,#ffffffff,#ffffffcc);
  --base-cyan: rgba(0, 224, 255, 1);
  --base-primary-light-8: rgba(239, 245, 255, 1);
  --base-golden-meidou: rgba(250, 181, 135, 1);
  --base-brown-meidou: rgba(87, 51, 27, 1);
  --base-gradient-nav: linear-gradient(90deg,#8e66ffff,#fb2dffff,#ffad33ff);
  --base-gradient-nav-tag: linear-gradient(180deg,#d1f9ffff,#d8d2ffff,#f1ff9bff);
  --base-zcool-brand-light-0: rgba(255, 195, 171, 1);
  --base-zcool-brand-light-10: rgba(255, 161, 139, 1);
  --base-zcool-brand-light-20: rgba(255, 128, 108, 1);
  --base-zcool-brand-light-30: rgba(255, 94, 77, 1);
  --base-zcool-brand-light-40: rgba(225, 57, 44, 1);
  --base-zcool-brand-light-50: rgba(189, 0, 0, 1);
  --base-zcool-brand-light-60: rgba(153, 0, 0, 1);
  --base-zcool-brand-light-70: rgba(118, 0, 0, 1);
  --base-zcool-brand-light-80: rgba(82, 0, 0, 1);
  --base-zcool-brand-light-90: rgba(46, 0, 0, 1);
  --base-zcool-brand-light-100: rgba(9, 0, 0, 1);
  --base-zcool-neutral-0: rgba(255, 255, 255, 1);
  --base-zcool-netural-1: rgba(249, 249, 249, 1);
  --base-zcool-neutral-5: rgba(237, 237, 237, 1);
  --base-zcool-neutral-11: rgba(220, 220, 220, 1);
  --base-zcool-neutral-16: rgba(203, 203, 203, 1);
  --base-zcool-neutral-21: rgba(186, 186, 186, 1);
  --base-zcool-neutral-26: rgba(170, 170, 170, 1);
  --base-zcool-neutral-32: rgba(153, 153, 153, 1);
  --base-zcool-neutral-37: rgba(137, 137, 137, 1);
  --base-zcool-neutral-42: rgba(122, 122, 122, 1);
  --base-zcool-neutral-47: rgba(107, 107, 107, 1);
  --base-zcool-neutral-53: rgba(92, 92, 92, 1);
  --base-zcool-neutral-58: rgba(77, 77, 77, 1);
  --base-zcool-neutral-63: rgba(63, 63, 63, 1);
  --base-zcool-neutral-68: rgba(50, 50, 50, 1);
  --base-zcool-neutral-74: rgba(37, 37, 37, 1);
  --base-zcool-neutral-79: rgba(24, 24, 24, 1);
  --base-zcool-neutral-84: rgba(13, 13, 13, 1);
  --base-zcool-neutral-89: rgba(4, 4, 4, 1);
  --base-zcool-neutral-95: rgba(0, 0, 0, 1);
  --base-zcool-neutral-100: rgba(0, 0, 0, 1);
  --base-zcool-natural-3: rgba(242, 242, 242, 1);
  --base-zcool-natural-2: rgba(246, 246, 246, 1);
  --base-zcool-natural-8: rgba(230, 230, 230, 1);

  /* Effect styles */
  --level-1:  0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.06);
  --level-2:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 6px 24px rgba(0, 0, 0, 0.06);
  --level-3:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 10px 40px rgba(0, 0, 0, 0.1);
  --level-4:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 24px 128px rgba(0, 0, 0, 0.16);
  --canvas-shadow:  0px 1px 3px rgba(0, 0, 0, 0.08);
  --web-bar:  0px 0px 12px rgba(0, 0, 0, 0.06);
  --tag-text:  0px 0px 1px rgba(0, 0, 0, 0.25);
  --level5:  0px 0px 8px rgba(0, 0, 0, 0.25);
  --separator:  0px -1px 0px rgba(0, 0, 0, 0.08);
  --new:  0px 2px 2px rgba(0, 0, 0, 0.16);
  --hover-stroke:  0px 0px 0px rgba(245, 245, 245, 1);
  --ai-editor-sidebar:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 6px 24px rgba(0, 0, 0, 0.06);
  --hailuo:  0px 4px 16px rgba(0, 0, 0, 0.12);
  --base-level-1-light:  0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.06);
  --base-level-2-light:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 6px 24px rgba(0, 0, 0, 0.06);
  --base-level-3-light:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 10px 40px rgba(0, 0, 0, 0.1);
  --base-level-4-light:  0px 0px 2px rgba(0, 0, 0, 0.08), 0px 24px 128px rgba(0, 0, 0, 0.16);
  --base-level-1-dark:  0px 2px 8px rgba(0, 0, 0, 0.15);
  --base-level-2-dark:  0px 6px 24px rgba(0, 0, 0, 0.25);
  --base-level-3-dark:  0px 10px 40px rgba(0, 0, 0, 0.36);
  --base-level-4-dark:  0px 24px 128px rgba(0, 0, 0, 0.5);
  --base-canvas-shadow-light:  0px 1px 3px rgba(0, 0, 0, 0.08);
  --base-canvas-shadow-dark:  0px 1px 3px rgba(0, 0, 0, 0.25);
  --base-web-bar-light:  0px 0px 12px rgba(0, 0, 0, 0.06);
  --base-web-bar-dark:  0px 0px 12px rgba(0, 0, 0, 0.25);
  --base-tag-text:  0px 0px 1px rgba(0, 0, 0, 0.25);
  --base-level-5-light:  0px 0px 8px rgba(0, 0, 0, 0.25);
  --base-tag-text-light:  0px 0px 1px rgba(0, 0, 0, 0.25);
  --base-separator-light:  0px -1px 0px rgba(0, 0, 0, 0.08);

  /* Text-size styles */
  --text-24: 24px;
  --text-20: 20px;
  --text-18: 18px;
  --text-16: 16px;
  --text-14: 14px;
  --text-12: 12px;
  --text-24-medium: 24px;
  --text-20-medium: 20px;
  --text-18-medium: 18px;
  --text-16-medium: 16px;
  --text-14-medium: 14px;
  --text-12-medium: 12px;
  --text-24-bold: 24px;
  --text-20-bold: 20px;
  --text-18-bold: 18px;
  --text-16-bold: 16px;
  --text-14-bold: 14px;
  --text-12-bold: 12px;
  --text-18-underline: 18px;
  --text-16-underline: 16px;
  --text-14-underline: 14px;
  --text-12-underline: 12px;
}